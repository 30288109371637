/* MainLayout.css */
.header {
  display: flex;
  align-items: center;
}

.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255);
  margin: 16px 24px 16px 0;
  float: left;
}

.menu-button {
  display: none;
}

@media (max-width: 768px) {
  .menu-button {
    display: block;
  }
}


@media (max-width: 768px) {
  .custom-drawer .ant-drawer-body {
    padding: 0;
  }
}

.container {
  display: flex;
  justify-content: center;
  max-width: 1000px;
  /* Increase the maximum width for the content */
  padding: 16px 24px;
  /* Add padding for spacing */
  box-sizing: border-box;
  /* Ensure padding doesn't affect overall width */
  width: 100%;
  /* Ensure the container takes full width */
  margin: 0 auto;
  /* Center the container horizontally */
  overflow: hidden;
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
    /* Full width on smaller screens */
    padding-left: 8px !important;
    /* Reduce padding for smaller screens */
    padding-right: 8px !important;
    padding-top: 0 !important;
    padding-bottom: 16px !important;
  }

  .tab-label {
    display: none;
  }

  .ant-table-cell {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.header {
  display: flex;
  align-items: center;
}

.logo {
  float: left;
  width: 100px;
  height: 31px;
  background-color: transparent;
  margin: 16px 24px 16px 0;
}

.menu-button {
  margin-left: auto;
}

.client-column {
  display: none;
}

@media (min-width: 768px) {
  .client-column {
    display: flex;
    align-items: center;
    flex: none;
  }
}